globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"19a74fa70ccfe6b3218b501051a1f2ee29f8ccfe"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { init, FullStory } from "@fullstory/browser";
import { DEPLOY_STAGE, FULLSTORY_ORG_ID, SENTRY_ORG_SLUG } from "./src/config";
import { fullStoryIntegration } from "@sentry/fullstory";

const deployStages: string[] = ["local", "staging", "prod"];

FULLSTORY_ORG_ID && init({ orgId: FULLSTORY_ORG_ID });

const sentryIntegrations = [
  fullStoryIntegration(SENTRY_ORG_SLUG, { client: FullStory }),
  Sentry.httpClientIntegration({
    failedRequestStatusCodes: [500],
  }),
];

if (DEPLOY_STAGE === "prod" || DEPLOY_STAGE === "staging") {
  sentryIntegrations.push(
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    })
  );
}

SENTRY_ORG_SLUG &&
  // Only initialize Sentry when DEPLOY_STAGE is set
  deployStages.includes(DEPLOY_STAGE) &&
  Sentry.init({
    dsn: "https://11de0cfc0baac913d36fd3333b437f8a@o4507572568850432.ingest.us.sentry.io/4507584110854144",

    environment: DEPLOY_STAGE,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: sentryIntegrations,
    sendDefaultPii: true,
  });
